<template>
  <div class="v-stack h-stretch gap-3" v-if="user">
    <a class="heading-title-2">Info</a>
    <div class="card light">
      <div class="content">
        <div class="v-stack gap-3">
          <div v-if="user.accessLevel > 4" class="pane-horizontal gap-3">
            <label class="text-right">Birthday: </label>
            <div class="text-left">
              {{
                user.birthday
                  ? formatDate(user.birthday, "D. M. YYYY")
                  : "Not set"
              }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Phone Number: </label>
            <div class="text-left">
              {{
                user.phoneNumber
                  ? format.telephone(user.phoneNumber)
                  : "Not set"
              }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Email: </label>
            <div class="text-left">{{ user.email }}</div>
          </div>
          <div class="pane-horizontal gap-3" v-if="user.client">
            <label class="text-right">Company: </label>
            <div class="text-left">{{ user.client.name }}</div>
          </div>
          <template v-if="user.accessLevel > 4">
            <div class="pane-horizontal gap-3">
              <label class="text-right">Address: </label>
              <div class="text-left">{{ user.address || "Not set" }}</div>
            </div>
            <div class="pane-horizontal gap-3">
              <label class="text-right">Guardian: </label>
              <div class="text-left">{{ user.guardian || "Not set" }}</div>
            </div>
          </template>
          <template v-if="user.accessLevel == 0">
            <div class="pane-horizontal gap-3">
              <label class="text-right">Professions: </label>
              <div v-if="user.categories.length == 0" class="text-left">
                Not set
              </div>
              <div class="v-stack gap-3 h-start">
                <div
                  class="text-left"
                  v-for="category in user.categories"
                  :key="category.name"
                >
                  {{ category.name }}
                  {{
                    $store.state.accessLevel > 5
                      ? `${format.currency(category.dailyFee)} Kč`
                      : ""
                  }}
                </div>
              </div>
            </div>
            <template v-if="$store.state.accessLevel > 5">
              <div class="pane-horizontal gap-3">
                <label class="text-right">Rating: </label>
                <div class="text-left">{{ user.rating || "Not set" }}</div>
              </div>
              <div class="pane-horizontal gap-3">
                <label class="text-right">Description: </label>
                <div class="text-left">
                  {{ user.description }}
                </div>
              </div>
            </template>
          </template>
          <template v-if="user.accessLevel > 4">
            <div class="pane-horizontal gap-3">
              <label class="text-right">Professions: </label>
              <div class="v-stack h-start gap-3">
                <div
                  v-for="proffession in user.categories"
                  :key="proffession.id"
                  class="text-left"
                >
                  {{ proffession.name }}
                </div>
              </div>
            </div>
            <div class="pane-horizontal gap-3">
              <label class="text-right">Description: </label>
              <div class="text-left">{{ user.description }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button
        v-if="
          $store.state.accessLevel > 9 ||
          $store.state.id == user._id ||
          ($store.state.accessLevel == 9 && user.accessLevel < 5)
        "
        @click="$router.push('/users/edit/' + user._id)"
        class="edit"
      >
        Edit
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
const moment = require("moment");
const utils = require("@/utils");

export default {
  props: {
    id: {
      type: String,
      default: () => Vue.store.state.id,
    },
  },
  data() {
    return {
      user: null,
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["getUser"]),
    sumWorkhours(workhours) {
      let result = 0;
      for (const workhour of workhours) {
        result += workhour.hours;
      }
      return result;
    },
    calculateMoney(workhours) {
      let result = 0;
      for (const workhour of workhours) {
        result += workhour.hours * workhour.pay.amount;
      }
      return Math.round(result);
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.getUser(this.id)
      .then((user) => {
        this.user = user;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
